export const config = {
  appId: "5af58981-696c-4cba-88c5-e1f5ad24e83d",
  authority: "https://login.microsoftonline.com/clifton-asset.co.uk",
  // redirectUri: "http://localhost:3000",
  // redirectUri: "https://cam-desk-booker.eu.ngrok.io",
  redirectUri:
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : ""),
  scopes: ["user.read", "calendars.readwrite", "sites.readwrite.all"],
  siteId:
    "cliftonasset.sharepoint.com,e8fadd55-3a5a-4808-877b-e4c08c505399,7bf39dee-7efa-4b33-a439-c0ecd9686a88",
  lookupListId: "745af9d0-db16-4a63-bdf6-a8b5017c9549",
  blockListId: "d2d74f7e-9d02-43e6-9ea6-1e757cd937bf",
  calendarListId: "feb78c92-8bb0-49f9-86e4-ae9d3625ce6e",
  weeksInAdvance: 4,
  calendarLink:
    "https://cliftonasset.sharepoint.com/teams/Vox/Lists/Desk%20Bookings/calendar.aspx",
};
