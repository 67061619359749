import { Client } from "@microsoft/microsoft-graph-client";
import { config } from "../Config";
import { ListItem, User } from "microsoft-graph";
import moment, { Moment } from "moment";

export interface DeskBooking {
  id: string;
  ContentType: string;
  Title: string;
  Modified: string;
  Created: string;
  AuthorLookupId: string;
  EditorLookupId: string;
  _UIVersionString: string;
  Attachments: boolean;
  Edit: string;
  LinkTitleNoMenu: string;
  LinkTitle: string;
  ItemChildCount: string;
  FolderChildCount: string;
  _ComplianceFlags: string;
  _ComplianceTag: string;
  _ComplianceTagWrittenTime: string;
  _ComplianceTagUserId: string;
  EventDate: string;
  EndDate: string;
  fAllDayEvent: boolean;
  fRecurrence: boolean;
}

function getAuthenticatedClient(accessToken: string): Client {
  // Initialize Graph client
  return Client.init({
    // Use the provided access token to authenticate requests
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });
}

export async function getUserDetails(accessToken: string): Promise<User> {
  const client: Client = getAuthenticatedClient(accessToken);

  return client.api("/me").get();
}

export async function getLookupId(
  accessToken: string,
  email: string
): Promise<string> {
  const client: Client = getAuthenticatedClient(accessToken);

  email = email.replace(/'/g, "''");

  const lookup = await client
    .api(`/sites/${config.siteId}/lists/${config.lookupListId}/items`)
    .header("Prefer", "allowthrottleablequeries")
    .filter(`fields/EMail eq '${email}'`)
    .select("id")
    .get();

  return lookup.value[0].id;
}

export async function getUserPhoto(accessToken: string): Promise<string> {
  const client: Client = getAuthenticatedClient(accessToken);

  const photo = await client.api("/me/photos/48x48/$value").get();

  return window.URL.createObjectURL(photo);
}

export async function getRestrictions(
  accessToken: string,
  lookupId: string
): Promise<ListItem[]> {
  const client: Client = getAuthenticatedClient(accessToken);

  const items: any = await client
    .api(`/sites/${config.siteId}/lists/${config.blockListId}/items`)
    .filter(`fields/EmployeeLookupId eq '${lookupId}'`)
    .expand("fields($select=Employee,IsBlockedBy)")
    .get();

  return items.value;
}

export async function getBookings(
  accessToken: string,
  userName: any
): Promise<DeskBooking[]> {
  const client: Client = getAuthenticatedClient(accessToken);

  const start: Moment = moment().startOf("day");
  const end: Moment = moment().add(config.weeksInAdvance, "week").endOf("week");
  if (!end.weekday()) {
    const subtract: number = end.day() === 7 ? 1 : 2;
    end.subtract(subtract, "day");
  }

  userName = userName.replace(/'/g, "''");

  const items: any = await client
    .api(`/sites/${config.siteId}/lists/${config.calendarListId}/items`)
    .filter(
      `fields/EndDate ge '${start.toISOString()}' and fields/EndDate le '${end.toISOString()}' and fields/Title eq '${userName}' `
    )
    .expand("fields")
    .get();

  return items.value.map((item: ListItem) => {
    return item.fields as DeskBooking;
  });
}

export async function postEvent(
  accessToken: string,
  name: string,
  date: Moment
): Promise<any> {
  const client: Client = getAuthenticatedClient(accessToken);
  return client
    .api(`/sites/${config.siteId}/lists/${config.calendarListId}/items`)
    .post({
      fields: {
        Title: name,
        EventDate: date.format("YYYY-MM-DD"),
        EndDate: date.format("YYYY-MM-DD"),
        fAllDayEvent: true,
      },
    });
}

export async function postOutlookEvent(
  accessToken: string,
  date: Moment
): Promise<any> {
  const client: Client = getAuthenticatedClient(accessToken);
  const start = date.startOf("day");
  const end = start.clone().add(1, "day").startOf("day");

  return client.api("/me/calendar/events").post({
    subject: "Office Desk Booking",
    showAs: "free",
    isAllDay: true,
    start: {
      dateTime: `${start.format("YYYY-MM-DD")}T00:00:00.000Z`,
      timeZone: "UTC",
    },
    end: {
      dateTime: `${end.format("YYYY-MM-DD")}T00:00:00.000Z`,
      timeZone: "UTC",
    },
  });
}

export async function deleteEvent(
  accessToken: string,
  eventID: string
): Promise<any> {
  const client: Client = getAuthenticatedClient(accessToken);
  return client
    .api(
      `/sites/${config.siteId}/lists/${config.calendarListId}/items/${eventID}`
    )
    .delete();
}
