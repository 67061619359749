import React from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import * as packageJson from "../../package.json";

interface NavBarProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface NavBarState {
  isOpen: boolean;
}

function UserAvatar(avatar: any) {
  // If a user avatar is available, return an img tag with the pic
  if (avatar !== null) {
    console.log(avatar);
    return (
      <img
        src={avatar.avatar}
        alt="user"
        className="rounded-circle align-self-center mr-2"
        style={{ width: "32px" }}
      />
    );
  }

  // No avatar available, return a default icon
  return (
    <i
      className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
      style={{ width: "32px" }}
    />
  );
}

function AuthNavItem(props: NavBarProps) {
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.isAuthenticated) {
    return (
      <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <UserAvatar avatar={props.user.avatar} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  // Not authenticated, return a sign in link
  return null;
}

export default class TopNavBar extends React.Component<
  NavBarProps,
  NavBarState
> {
  constructor(props: NavBarProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const backgroundColour = "yellowNav";

    const navBar = this.props.isAuthenticated ? (
      <Navbar className={`${backgroundColour}`} dark fixed="top">
        <NavbarBrand href="/" className="pt-0">
          <svg version="1.1" viewBox="0 0 73 84.3" height="30" className="logo">
            <g>
              <path
                d="M65,40.3l-6.8-3.5L73,28.2v-7.1l-0.4-0.2l-5.3-3.1l-14.7,8.6v-7.8l8-4.7L36.9,0.2L36.5,0l-0.4,0.2L12.4,13.9
			l8,4.7v7.8L6.6,18.2l0,0l-0.8-0.5l-5.4,3.1L0,21.1v7.1l14.8,8.6l-6.7,3.5l-7.2-4.2l0,0L0,35.7v27.5l0.4,0.2L24,77.1v-9.2l6.5-4.1
			v17.1l5.6,3.2l0.4,0.2l0.4-0.2l5.6-3.2V63.7l6.5,4.1v9.2l23.6-13.6l0.4-0.2V35.8L65,40.3z M72.2,62.7L50,75.6V68l10.7-6.2
			L36.5,47.9L12.4,61.8L23,68v7.6L0.8,62.7V37.3L7.4,41v12.4l24.1-13.9V11.6l-10.6,6.1l-6.8-3.9L36.5,1l22.4,12.9l-6.8,3.9
			l-10.7-6.1v27.9l24.2,13.9V41l6.6-3.8L72.2,62.7z"
              />
            </g>
          </svg>{" "}
          Clifton Group - Desk Booker <small>v{packageJson.version}</small>
        </NavbarBrand>
        <Nav className="justify-content-end" navbar>
          <AuthNavItem
            isAuthenticated={this.props.isAuthenticated}
            authButtonMethod={this.props.authButtonMethod}
            user={this.props.user}
          />
        </Nav>
      </Navbar>
    ) : null;

    return <div>{navBar}</div>;
  }
}
