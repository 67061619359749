import React from "react";
import { Alert, Button, Modal, ModalBody, Spinner } from "reactstrap";
import moment, { CalendarSpec, Moment } from "moment";
import { config } from "../Config";
import {
  deleteEvent,
  DeskBooking,
  getBookings,
  getLookupId,
  getRestrictions,
  getUserDetails,
  postEvent,
  postOutlookEvent,
} from "../api/GraphService";
import withAuthProvider, { AuthComponentProps } from "../hoc/AuthProvider";
import { ListItem, User } from "microsoft-graph";

interface CalendarDay {
  name: string;
  date: string;
  status: string;
  eventID: string | null;
}

interface CalendarState {
  events: CalendarDay[];
  name: string | null;
  booking: boolean;
}
const hasRestrictions: boolean = false;
const colours: { [status: string]: string } = {
  [`Available`]: "info",
  [`You are booked in`]: "success",
};

class Calendar extends React.Component<AuthComponentProps, CalendarState> {
  private readonly calendarFormat: CalendarSpec;

  constructor(props: any) {
    super(props);

    this.state = {
      events: [],
      name: null,
      booking: false,
    };

    this.calendarFormat = {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "dddd",
    };
  }

  async componentDidMount(): Promise<void> {
    await this.updateCalendar();
  }

  private async updateCalendar(): Promise<void> {
    try {
      // Get the user's access token
      const accessToken: string = await this.props.getAccessToken(
        config.scopes
      );
      const user: User = await getUserDetails(accessToken);
      const lookupId: string = await getLookupId(
        accessToken,
        user.mail as string
      );

      let names: any[] = hasRestrictions ? [] : [user.displayName];
      if (hasRestrictions) {
        let people: ListItem[] = await getRestrictions(accessToken, lookupId);
        if (
          people !== null &&
          people.length > 0 &&
          typeof people[0] !== "undefined"
        ) {
          const employee: any = people[0].fields;
          if (employee.hasOwnProperty("IsBlockedBy")) {
            names = employee.IsBlockedBy.map((blocker: any) => {
              return blocker.LookupValue;
            });
          }
        }
        names.push(user.displayName as string);
      }

      const events: DeskBooking[] = await this.getFilteredEvents(
        accessToken,
        names,
        user
      );

      const calendar = this.buildCalendar(events);

      // Update the array of events in state
      this.setState({
        events: calendar,
        name: user.displayName as string,
      });
    } catch (err) {
      this.props.setError("ERROR", JSON.stringify(err));
      this.setState({ booking: false });
    }
  }

  private buildCalendar(events: DeskBooking[]): CalendarDay[] {
    const current: Moment = moment();
    const end: Moment = current
      .clone()
      .add(config.weeksInAdvance, "week")
      .endOf("week");
    const calendar: CalendarDay[] = [];

    while (current <= end) {
      if (current.day() > 0 && current.day() < 6) {
        const day: Moment = current.clone();
        const name: string = day.calendar(this.calendarFormat);
        const booking: DeskBooking[] = events.filter((event: DeskBooking) =>
          moment(event.EventDate).isSame(day, "day")
        );

        const status: string =
          booking.length === 0 ? "Available" : booking[0].Title;

        const eventID: string | null =
          booking.length === 0 ? null : booking[0].id;

        calendar.push({
          name,
          date: day.format("DD/MM/YYYY"),
          status,
          eventID,
        });
      }
      current.add(1, "day");
    }
    return calendar;
  }

  private async getFilteredEvents(
    accessToken: string,
    names: any,
    user: User
  ): Promise<DeskBooking[]> {
    const events: DeskBooking[] = await getBookings(
      accessToken,
      user.displayName
    );

    return events
      .filter((event: DeskBooking) => names.includes(event.Title))
      .map((event: DeskBooking) => {
        if (event.Title === user.displayName) {
          event.Title = "You are booked in";
        } else {
          event.Title = `${event.Title} is booked in`;
        }

        return event;
      });
  }

  private async createBooking(date: string) {
    this.setState({ booking: true });
    const accessToken: string = await this.props.getAccessToken(config.scopes);
    const name = this.props.user.displayName;

    await postEvent(accessToken, name, moment(date, "DD/MM/YYYY"));
    await postOutlookEvent(accessToken, moment(date, "DD/MM/YYYY"));
    await this.updateCalendar();
    this.setState({ booking: false });
  }

  private async cancelBooking(eventID: string) {
    this.setState({ booking: true });
    const accessToken: string = await this.props.getAccessToken(config.scopes);

    await deleteEvent(accessToken, eventID);
    await this.updateCalendar();
    this.setState({ booking: false });
  }

  render() {
    return (
      <div
        className="pb-5 px-3 pt-3 pb-0 mb-0 full-height"
        style={{
          background: "#ffffff",
          flex: 1,
        }}
      >
        <Alert color="warning" className="small">
          <div className="mt-3">
            <p>
              Need to know who else is in on particular day? Have a look at the
              calendar
            </p>
            <Button color="warning" size="sm">
              <a href={config.calendarLink} className="text-body">
                View the calendar
              </a>
            </Button>
          </div>
        </Alert>
        {this.state.events.map((event, i) => {
          const colour: string = colours[event.status] || "danger";
          const todayColour: string =
            event.name === "Today" ? "text-primary" : "";
          const dateColour: string =
            event.name === "Today" ? "text-primary" : "text-muted";

          return (
            <div key={i}>
              {i > 0 && <hr className="mx-2" />}
              <div className="d-flex justify-content-between align-self-center">
                <div
                  className={`border-left-5 border-lg border-${colour} rounded pl-2`}
                >
                  <div
                    className={`${todayColour} text-uppercase`}
                    style={{ fontSize: "0.8em" }}
                  >
                    <span className="font-weight-bold">{event.name}</span>{" "}
                    <span className={dateColour}>{event.date}</span>
                  </div>
                  <div>
                    <span className={`align-self-center text-muted`}>
                      {event.status}
                    </span>
                  </div>
                </div>
                <div className="justify-content-end align-self-center">
                  {event.status === "Available" && (
                    <Button
                      color="success"
                      outline
                      size="sm"
                      disabled={this.state.booking}
                      onClick={() => this.createBooking(event.date)}
                    >
                      Book
                    </Button>
                  )}
                  {event.status === "You are booked in" && (
                    <Button
                      color="danger"
                      outline
                      size="sm"
                      disabled={this.state.booking}
                      onClick={() =>
                        this.cancelBooking(event.eventID as string)
                      }
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <Modal
          isOpen={this.state.booking}
          centered={true}
          fade={false}
          className="loadingModal"
        >
          <ModalBody className="text-center">
            <Spinner
              color="success"
              style={{ width: "3rem", height: "3rem" }}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withAuthProvider(Calendar);
