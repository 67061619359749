import React, { Component } from "react";
import { Spinner } from "reactstrap";
import withAuthProvider, { AuthComponentProps } from "../hoc/AuthProvider";
import TopNavBar from "../components/TopNavBar";
import BottomNavBar from "../components/BottomNavBar";
import Calendar from "../components/Calendar";
import "bootstrap/dist/css/bootstrap.css";
import Welcome from "../components/Welcome";

class App extends Component<AuthComponentProps> {
  render() {
    let content;

    if (this.props.booting) {
      content = (
        <div className="row align-items-center align-self-center full-height">
          <div className="col text-center">
            <h4 className="mb-3">Loading...</h4>
            <Spinner
              color="primary"
              style={{ width: "3rem", height: "3rem" }}
            />
          </div>
        </div>
      );
    } else {
      content = this.props.isAuthenticated ? (
        <Calendar {...this.props} />
      ) : (
        <Welcome
          {...this.props}
          isAuthenticated={this.props.isAuthenticated}
          user={this.props.user}
          authButtonMethod={this.props.login}
          booting={this.props.booting}
        />
      );
    }

    const backgroundColour = this.props.isAuthenticated ? "white" : "yellow";

    return (
      <div className={`d-flex flex-column ${backgroundColour} pt-0 mt-0`}>
        <TopNavBar
          isAuthenticated={this.props.isAuthenticated}
          authButtonMethod={
            this.props.isAuthenticated ? this.props.logout : this.props.login
          }
          user={this.props.user}
        />
        {content}
        <BottomNavBar isAuthenticated={this.props.isAuthenticated} />
      </div>
    );
  }
}

export default withAuthProvider(App);
