import React from "react";
import { Button } from "reactstrap";
import { User } from "microsoft-graph";
import * as packageJson from "../../package.json";

interface WelcomeProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: User;
  booting: boolean;
}

interface WelcomeState {
  isOpen: boolean;
}

export default class Welcome extends React.Component<
  WelcomeProps,
  WelcomeState
> {
  render() {
    return (
      <div
        className="d-flex flex-column justify-content-around fuller-height"
        style={{ marginTop: "-64px" }}
      >
        <div className="mx-5 pt-5 mb-2">
          <img
            src="remote-work-man.svg"
            className="img-fluid"
            alt="Remote working man"
          />
        </div>
        <div className="my-5 d-flex justify-content-around align-self-center">
          <div>
            <svg
              version="1.1"
              viewBox="0 0 73 84.3"
              width="70"
              className="logo"
            >
              <g>
                <path
                  d="M65,40.3l-6.8-3.5L73,28.2v-7.1l-0.4-0.2l-5.3-3.1l-14.7,8.6v-7.8l8-4.7L36.9,0.2L36.5,0l-0.4,0.2L12.4,13.9
			l8,4.7v7.8L6.6,18.2l0,0l-0.8-0.5l-5.4,3.1L0,21.1v7.1l14.8,8.6l-6.7,3.5l-7.2-4.2l0,0L0,35.7v27.5l0.4,0.2L24,77.1v-9.2l6.5-4.1
			v17.1l5.6,3.2l0.4,0.2l0.4-0.2l5.6-3.2V63.7l6.5,4.1v9.2l23.6-13.6l0.4-0.2V35.8L65,40.3z M72.2,62.7L50,75.6V68l10.7-6.2
			L36.5,47.9L12.4,61.8L23,68v7.6L0.8,62.7V37.3L7.4,41v12.4l24.1-13.9V11.6l-10.6,6.1l-6.8-3.9L36.5,1l22.4,12.9l-6.8,3.9
			l-10.7-6.1v27.9l24.2,13.9V41l6.6-3.8L72.2,62.7z"
                />
              </g>
            </svg>
          </div>
          <div className="ml-3">
            <h1
              className="font-weight-bold"
              style={{ lineHeight: "1", color: "#004731" }}
            >
              Clifton Group
              <br />
              <small>
                Desk Booker <small>v{packageJson.version}</small>
              </small>
            </h1>
          </div>
        </div>
        <div className="text-center mb-5 pb-5">
          <Button color="transparent" onClick={this.props.authButtonMethod}>
            <img src="ms-logo.svg" alt="Sign in with Microsoft" />
          </Button>
        </div>
      </div>
    );
  }
}
