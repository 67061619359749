import React from "react";
import { Navbar } from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.css";

interface NavBarProps {
  isAuthenticated: boolean;
}

interface NavBarState {
  isOpen: boolean;
}

export default class BottomNavBar extends React.Component<
  NavBarProps,
  NavBarState
> {
  constructor(props: NavBarProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const backgroundColour = "white";

    const navBar = this.props.isAuthenticated ? (
      <Navbar className={`${backgroundColour}`} dark fixed="bottom" />
    ) : null;

    return <div>{navBar}</div>;
  }
}
