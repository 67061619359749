import React from "react";
import {
  PublicClientApplication,
  AuthenticationResult,
} from "@azure/msal-browser";

import { config } from "../Config";
import { getUserDetails, getUserPhoto } from "../api/GraphService";
import { User } from "microsoft-graph";

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  user: any;
  booting: boolean;
  login: Function;
  logout: Function;
  getAccessToken: Function;
  setError: Function;
}

interface AuthProviderState {
  error: any;
  isAuthenticated: boolean;
  user: any;
  booting: boolean;
}

export default function withAuthProvider<
  T extends React.Component<AuthComponentProps>
>(
  WrappedComponent: new (props: AuthComponentProps, context?: any) => T
): React.ComponentClass {
  return class extends React.Component<any, AuthProviderState> {
    private userAgentApplication: PublicClientApplication;

    constructor(props: any) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        user: {},
        booting: true,
      };

      // Initialize the MSAL application object
      this.userAgentApplication = new PublicClientApplication({
        auth: {
          clientId: config.appId,
          authority: config.authority,
          redirectUri: config.redirectUri,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "localStorage",
        },
      });
    }

    async componentDidMount() {
      try {
        const tokenResponse: AuthenticationResult | null = await this.userAgentApplication.handleRedirectPromise();
        if (tokenResponse !== null) {
          await this.getUserProfile();
          this.setState({ booting: false });
        } else {
          const currentAccounts = this.userAgentApplication.getAllAccounts();
          if (currentAccounts === null) {
            // No user signed in
            this.setState({ booting: false, isAuthenticated: false, user: {} });
          } else {
            await this.getUserProfile();
          }
        }
      } catch (error) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(error),
          booting: false,
        });
      }
    }

    render() {
      return (
        <WrappedComponent
          error={this.state.error}
          isAuthenticated={this.state.isAuthenticated}
          user={this.state.user}
          booting={this.state.booting}
          login={() => this.login()}
          logout={() => this.logout()}
          getAccessToken={(scopes: string[]) => this.getAccessToken(scopes)}
          setError={(message: string, debug: string) =>
            this.setErrorMessage(message, debug)
          }
          {...this.props}
        />
      );
    }

    login() {
      // Login via popup
      this.userAgentApplication.loginRedirect({
        scopes: config.scopes,
        prompt: "select_account",
      });
    }

    logout() {
      this.userAgentApplication.logout();
    }

    async getAccessToken(scopes: string[]): Promise<string> {
      try {
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        const account = this.userAgentApplication.getAllAccounts()[0];
        const silentResult: AuthenticationResult = await this.userAgentApplication.acquireTokenSilent(
          {
            scopes: scopes,
            account,
            forceRefresh: true,
          }
        );

        return silentResult.accessToken;
      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          const interactiveResult: AuthenticationResult = await this.userAgentApplication.acquireTokenPopup(
            {
              scopes: scopes,
            }
          );

          return interactiveResult.accessToken;
        } else {
          throw err;
        }
      }
    }

    async getUserProfile() {
      try {
        const accessToken: string = await this.getAccessToken(config.scopes);

        if (accessToken) {
          // Get the user's profile from Graph
          const user: User = await getUserDetails(accessToken);
          const avatar: string | null = await this.getUserAvatar();
          this.setState({
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              avatar,
            },
            error: null,
            booting: false,
          });
        }
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err),
          booting: false,
        });
      }
    }

    async getUserAvatar(): Promise<string | null> {
      try {
        const accessToken: string = await this.getAccessToken(config.scopes);

        if (accessToken) {
          // Get the user's profile from Graph
          return await getUserPhoto(accessToken);
        }
      } catch (err) {
        this.setState({
          error: this.normalizeError(err),
        });
      }
      return null;
    }

    setErrorMessage(message: string, debug: string) {
      this.setState({
        error: { message: message, debug: debug },
      });
    }

    normalizeError(error: string | Error): any {
      let normalizedError: {} = {};
      if (typeof error === "string") {
        const errParts = error.split("|");
        normalizedError =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error),
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error: Error): boolean {
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf("consent_required") > -1 ||
        error.message.indexOf("interaction_required") > -1 ||
        error.message.indexOf("login_required") > -1
      );
    }
  };
}
